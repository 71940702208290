<template>
  <div>
    <v-navigation-drawer v-model="hide" app clipped>
      <v-container>
        <v-layout justify-center mt-3>
          <p class="text--secondary">
            <strong>{{ user.name }}</strong>
          </p>
        </v-layout>
        <v-divider></v-divider>
        <v-list dense mt-3>
          <v-list-item v-show="isStudent" link @click="showStudent()">
            <v-list-item-action>
              <v-icon>person</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Meu Perfil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-show="isCandidate" link @click="showRequests()">
            <v-list-item-action>
              <v-icon>format_list_bulleted</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Solicitações</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-show="isCandidate" link @click="showRequest()">
            <v-list-item-action>
              <v-icon>description</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Inscrição</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-show="isCandidate" link @click="showDocumets()">
            <v-list-item-action>
              <v-icon>folder</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Documentos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-show="isAdmin || isStaff" link @click="showStudents()">
            <v-list-item-action>
              <v-icon>school</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Estudantes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-show="isFaculty" link @click="showGuidances()">
            <v-list-item-action>
              <v-icon color="grey">school</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Orientação</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-show="isAdmin || isStaff"
            link
            @click="showGeneralGuidances()"
          >
            <v-list-item-action>
              <v-icon>supervisor_account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Docentes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-show="isAdmin || isStaff || isFaculty"
            link
            @click="showClasses()"
          >
            <v-list-item-action>
              <v-icon>supervised_user_circle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Turmas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

            <v-list-group
            v-show="isAdmin || isFaculty"
            prepend-icon="school"
          >
            <template v-slot:activator>
              <v-list-item-title>Especial</v-list-item-title>
            </template>
            <v-container>
              <v-list-item
                v-show="isAdmin"
                link @click="showEnrollmentPeriod()"
              >
                <v-list-item-action class="mr-3">
                  <v-icon>event</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Períodos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-show="isAdmin || isFaculty"
                link @click="showEnrollmentRequests()"
              >
                <v-list-item-action class="mr-3">
                  <v-icon>description</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Solicitações</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-container>
          </v-list-group>

          <v-list-group
            v-show="isAdmin || isEditor || isStudent"
            prepend-icon="description"
          >
            <template v-slot:activator>
              <v-list-item-title>Relatórios</v-list-item-title>
            </template>

            <v-container>
              <v-list-item
                v-show="isAdmin || isEditor"
                link
                @click="showEditors()"
              >
                <v-list-item-action class="mr-3">
                  <v-icon>people</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Editores</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-show="isStudent || isEditor || isAdmin"
                link
                @click="showTechincalReports()"
              >
                <v-list-item-action class="mr-3">
                  <v-icon>description</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Relatórios</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-container>
          </v-list-group>

          <v-list-group
            v-show="isAdmin || isStaff"
            prepend-icon="business_center"
          >
            <template v-slot:activator>
              <v-list-item-title>Bolsas</v-list-item-title>
            </template>

            <v-container>
              <v-list-item link @click="showFundingAgency()">
                <v-list-item-action>
                  <v-icon>navigate_next</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Agências</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link @click="showScholarshipQuota()">
                <v-list-item-action>
                  <v-icon>navigate_next</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Cotas</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link @click="showScholarship()">
                <v-list-item-action>
                  <v-icon>navigate_next</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Bolsas</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-container>
          </v-list-group>

          <v-list-group v-show="isAdmin || isStaff" prepend-icon="announcement">
            <template v-slot:activator>
              <v-list-item-title>Ocorrências</v-list-item-title>
            </template>

            <v-container>
              <v-list-item link @click="showOccurrenceType()">
                <v-list-item-action>
                  <v-icon>navigate_next</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Tipos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link @click="showOccurrences()">
                <v-list-item-action>
                  <v-icon>navigate_next</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Ocorrências</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-container>
          </v-list-group>

          <v-list-group prepend-icon="settings">
            <template v-slot:activator>
              <v-list-item-title>Configuração</v-list-item-title>
            </template>

            <v-container>
              <v-list-item v-show="isCandidate" link @click="showEdit()">
                <v-list-item-action class="mr-3">
                  <v-icon>edit</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-show="isAdmin" link @click="showAdmin()">
                <v-list-item-action class="mr-3">
                  <v-icon>supervised_user_circle</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Administradores</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-show="isAdmin" link @click="showActiveUsers()">
                <v-list-item-action class="mr-3">
                  <v-icon>supervisor_account</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Usuários Ativos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-show="isCandidate"
                link
                @click="showUpdatePassword()"
              >
                <v-list-item-action class="mr-3">
                  <v-icon>lock</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Senha</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-show="isAdmin" link @click="showDatabaseUpdate()">
                <v-list-item-action class="mr-3">
                  <v-icon>refresh</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Atualizações</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link @click="darkTheme">
                <v-list-item-action class="mr-3">
                  <v-icon medium>brightness_medium</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ theme }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-container>
          </v-list-group>

          <v-list-item link @click="showFeedback()">
            <v-list-item-action>
              <v-icon>bug_report</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Bug Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="showInfo()">
            <v-list-item-action>
              <v-icon>help_outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sobre</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="handleLogOut()">
            <v-list-item-action>
              <v-icon>cancel</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Menu",
  props: {
    user: {
      type: Object,
      required: true,
    },
    drawer: {
      type: Boolean,
      required: true,
    },
    list: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      theme: "Tema Escuro",
      hide: false,
    };
  },

  created() {
    this.hide = this.drawer == true ? true : false;
  },

  watch: {
    drawer(newValue) {
      this.hide = newValue == true ? true : false;
    },
  },

  computed: {
    isAdmin() {
      return this.user.roles.includes("admin");
    },
    isStaff() {
      return this.user.roles.includes("staff");
    },
    isFaculty() {
      return this.user.roles.includes("faculty");
    },
    isStudent() {
      return this.user.roles.includes("student");
    },
    isCandidate() {
      return this.user.roles.includes("candidate");
    },
    isEditor() {
      return this.user.roles.includes("editor");
    },
  },

  methods: {
    ...mapActions("auth", ["ActionSignOut"]),

    handleLogOut() {
      this.ActionSignOut();
      this.$router.push("/");
    },

    darkTheme() {
      const op = localStorage.getItem("theme");
      const isDark = op == "true";
      if (isDark) {
        this.$vuetify.theme.dark = false;
        this.theme = "Tema Escuro";
        localStorage.setItem("theme", false);
      }
      if (!isDark) {
        this.$vuetify.theme.dark = true;
        this.theme = "Tema Claro";
        localStorage.setItem("theme", true);
      }
    },

    showStudents() {
      this.$emit("router-path", { name: "Students" });
    },

    showGeneralGuidances() {
      this.$emit("router-path", { name: "Professors" });
    },

    showFundingAgency() {
      this.$emit("router-path", { name: "FundingAgency" });
    },

    showAdmin() {
      this.$emit("router-path", { name: "GeneralAdmin" });
    },

    showActiveUsers() {
      this.$emit("router-path", { name: "ActiveUsers" });
    },

    showGuidances() {
      this.$emit("router-path", {
        name: "Professor",
        params: { academic_code: this.user.academicCode, professor: this.user },
      });
    },

    showDatabaseUpdate() {
      this.$emit("router-path", { name: "DatabaseUpdate" });
    },

    showOccurrenceType() {
      this.$emit("router-path", { name: "OccurrenceType" });
    },

    showScholarship() {
      this.$emit("router-path", { name: "Scholarship" });
    },

    showScholarshipQuota() {
      this.$emit("router-path", { name: "ScholarshipQuota" });
    },

    showOccurrences() {
      this.$emit("router-path", { name: "AdminOccurrences" });
    },

    showClasses() {
      this.$emit("router-path", { name: "Classes" });
    },

    showFeedback() {
      this.$emit("toggle", "feedback");
    },

    showInfo() {
      this.$emit("toggle", "info");
    },

    showStudent() {
      if (this.isStudent) {
        this.$emit("router-path", {
          name: "Student",
          params: { academic_code: this.user.academicCode, student: this.user },
        });
      }
    },

    showRequests() {
      this.$emit("router-path", {
        name: "EnrollmentRequest",
        params: { username: this.user.username },
      });
    },

    showRequest() {
      this.$emit("router-path", {
        name: "CandidateEnrollment",
        params: { username: this.user.username },
      });
    },

    showDocumets() {
      this.$emit("router-path", {
        name: "FilesTable",
        params: { username: this.user.username, readonly: false },
        query: { username: this.user.username, readonly: false }
      });
    },

    showEdit() {
      this.$emit("router-path", {
        name: "EditCandidate",
        params: { username: this.user.username },
      });
    },

    showUpdatePassword() {
      this.$emit("router-path", {
        name: "CandidatePassword",
      }); 
    },

    showEditors() {
      this.$emit("router-path", { name: "Editors" });
    },

    showTechincalReports() {
      this.$emit("router-path", {
        name: "TechnicalReports",
        params: { username: this.user.username },
      });
    },

    showEnrollmentPeriod(){
      this.$emit('router-path', { name: 'EnrollmentPeriod' });
    },

    showEnrollmentRequests(){
      if (this.isFaculty){
        this.$emit('router-path', { name: 'FacultyOpinion', params: { username: this.user.username }});
      }

      if (this.isCandidate){
        this.$emit('router-path', { name: 'EnrollmentRequest', params: { username: this.user.username }});
      }
    }
  },
};
</script>

<style>
</style>
