var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          _vm.info = false
        }
      }
    },
    [
      _c("Bar", { on: { "toggle-drawer": _vm.toggle } }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "350" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.info = false
            }
          },
          model: {
            value: _vm.info,
            callback: function($$v) {
              _vm.info = $$v
            },
            expression: "info"
          }
        },
        [_c("Info")],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.feedback = false
            }
          },
          model: {
            value: _vm.feedback,
            callback: function($$v) {
              _vm.feedback = $$v
            },
            expression: "feedback"
          }
        },
        [_c("Feedback")],
        1
      ),
      _c("Menu", {
        attrs: { drawer: _vm.drawer, user: _vm.user },
        on: { "router-path": _vm.routerPath, toggle: _vm.toggle }
      }),
      _c(
        "v-main",
        { attrs: { id: "content" } },
        [
          _c(
            "v-container",
            { staticClass: "fill-height", attrs: { fluid: "" } },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }