<template>
  <div @keydown.esc="info = false">
    <Bar v-on:toggle-drawer="toggle"></Bar>

    <v-dialog v-model="info" max-width="350" @keydown.esc="info = false">
      <Info></Info>
    </v-dialog>

    <v-dialog
      v-model="feedback"
      max-width="800"
      @keydown.esc="feedback = false"
    >
      <Feedback></Feedback>
    </v-dialog>

    <Menu
      :drawer="drawer"
      :user="user"
      v-on:router-path="routerPath"
      v-on:toggle="toggle"
    ></Menu>

    <v-main id="content">
      <v-container class="fill-height" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as storage from "@/pages/auth/storage";

import Menu from "@/components/common/home/Menu";
import Bar from "@/components/common/home/Bar";
import Feedback from "@/components/common/home/Feedback";
import Info from "@/components/common/home/Info";

export default {
  name: "home",
  props: [],
  components: { Menu, Bar, Feedback, Info },

  data() {
    return {
      drawer: true,
      feedback: false,
      info: false,
      user: {},
    };
  },

  created() {
    this.user = storage.getLocalData().user;
  },

  methods: {
    ...mapActions("auth", ["ActionSignOut"]),

    handleLogOut() {
      this.ActionSignOut();
      this.$router.push("/");
    },

    toggleDrawer() {
      this.drawer = !this.drawer;
    },

     toggle(data) {
      switch (data) {
        case "drawer":
          this.drawer = !this.drawer;
          break;
        case "info":
          this.info = !this.info;
          break;
        case "feedback":
          this.feedback = !this.feedback;
          break;
      }
    },

    routerPath(data) {
      this.$router.push(data);
    },
  },
};
</script>

<style scoped>
</style>