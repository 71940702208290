var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", clipped: "" },
          model: {
            value: _vm.hide,
            callback: function($$v) {
              _vm.hide = $$v
            },
            expression: "hide"
          }
        },
        [
          _c(
            "v-container",
            [
              _c("v-layout", { attrs: { "justify-center": "", "mt-3": "" } }, [
                _c("p", { staticClass: "text--secondary" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.user.name))])
                ])
              ]),
              _c("v-divider"),
              _c(
                "v-list",
                { attrs: { dense: "", "mt-3": "" } },
                [
                  _c(
                    "v-list-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isStudent,
                          expression: "isStudent"
                        }
                      ],
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.showStudent()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("person")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Meu Perfil")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCandidate,
                          expression: "isCandidate"
                        }
                      ],
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.showRequests()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("format_list_bulleted")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Solicitações")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCandidate,
                          expression: "isCandidate"
                        }
                      ],
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.showRequest()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("description")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Inscrição")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCandidate,
                          expression: "isCandidate"
                        }
                      ],
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.showDocumets()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("folder")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Documentos")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isAdmin || _vm.isStaff,
                          expression: "isAdmin || isStaff"
                        }
                      ],
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.showStudents()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("school")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Estudantes")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isFaculty,
                          expression: "isFaculty"
                        }
                      ],
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.showGuidances()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-icon", { attrs: { color: "grey" } }, [
                            _vm._v("school")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Orientação")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isAdmin || _vm.isStaff,
                          expression: "isAdmin || isStaff"
                        }
                      ],
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.showGeneralGuidances()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("supervisor_account")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Docentes")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isAdmin || _vm.isStaff || _vm.isFaculty,
                          expression: "isAdmin || isStaff || isFaculty"
                        }
                      ],
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.showClasses()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("supervised_user_circle")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Turmas")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-group",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isAdmin || _vm.isFaculty,
                          expression: "isAdmin || isFaculty"
                        }
                      ],
                      attrs: { "prepend-icon": "school" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function() {
                            return [
                              _c("v-list-item-title", [_vm._v("Especial")])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isAdmin,
                                  expression: "isAdmin"
                                }
                              ],
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showEnrollmentPeriod()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                { staticClass: "mr-3" },
                                [_c("v-icon", [_vm._v("event")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [_c("v-list-item-title", [_vm._v("Períodos")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isAdmin || _vm.isFaculty,
                                  expression: "isAdmin || isFaculty"
                                }
                              ],
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showEnrollmentRequests()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                { staticClass: "mr-3" },
                                [_c("v-icon", [_vm._v("description")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Solicitações")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-group",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isAdmin || _vm.isEditor || _vm.isStudent,
                          expression: "isAdmin || isEditor || isStudent"
                        }
                      ],
                      attrs: { "prepend-icon": "description" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function() {
                            return [
                              _c("v-list-item-title", [_vm._v("Relatórios")])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isAdmin || _vm.isEditor,
                                  expression: "isAdmin || isEditor"
                                }
                              ],
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showEditors()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                { staticClass: "mr-3" },
                                [_c("v-icon", [_vm._v("people")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [_c("v-list-item-title", [_vm._v("Editores")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.isStudent ||
                                    _vm.isEditor ||
                                    _vm.isAdmin,
                                  expression: "isStudent || isEditor || isAdmin"
                                }
                              ],
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showTechincalReports()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                { staticClass: "mr-3" },
                                [_c("v-icon", [_vm._v("description")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Relatórios")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-group",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isAdmin || _vm.isStaff,
                          expression: "isAdmin || isStaff"
                        }
                      ],
                      attrs: { "prepend-icon": "business_center" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function() {
                            return [_c("v-list-item-title", [_vm._v("Bolsas")])]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showFundingAgency()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                [_c("v-icon", [_vm._v("navigate_next")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [_c("v-list-item-title", [_vm._v("Agências")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showScholarshipQuota()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                [_c("v-icon", [_vm._v("navigate_next")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [_c("v-list-item-title", [_vm._v("Cotas")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showScholarship()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                [_c("v-icon", [_vm._v("navigate_next")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [_c("v-list-item-title", [_vm._v("Bolsas")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-group",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isAdmin || _vm.isStaff,
                          expression: "isAdmin || isStaff"
                        }
                      ],
                      attrs: { "prepend-icon": "announcement" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function() {
                            return [
                              _c("v-list-item-title", [_vm._v("Ocorrências")])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showOccurrenceType()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                [_c("v-icon", [_vm._v("navigate_next")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [_c("v-list-item-title", [_vm._v("Tipos")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showOccurrences()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                [_c("v-icon", [_vm._v("navigate_next")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Ocorrências")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-group",
                    {
                      attrs: { "prepend-icon": "settings" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function() {
                            return [
                              _c("v-list-item-title", [_vm._v("Configuração")])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isCandidate,
                                  expression: "isCandidate"
                                }
                              ],
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showEdit()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                { staticClass: "mr-3" },
                                [_c("v-icon", [_vm._v("edit")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [_c("v-list-item-title", [_vm._v("Editar")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isAdmin,
                                  expression: "isAdmin"
                                }
                              ],
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showAdmin()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                { staticClass: "mr-3" },
                                [
                                  _c("v-icon", [
                                    _vm._v("supervised_user_circle")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Administradores")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isAdmin,
                                  expression: "isAdmin"
                                }
                              ],
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showActiveUsers()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                { staticClass: "mr-3" },
                                [_c("v-icon", [_vm._v("supervisor_account")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Usuários Ativos")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isCandidate,
                                  expression: "isCandidate"
                                }
                              ],
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showUpdatePassword()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                { staticClass: "mr-3" },
                                [_c("v-icon", [_vm._v("lock")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [_c("v-list-item-title", [_vm._v("Senha")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isAdmin,
                                  expression: "isAdmin"
                                }
                              ],
                              attrs: { link: "" },
                              on: {
                                click: function($event) {
                                  return _vm.showDatabaseUpdate()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                { staticClass: "mr-3" },
                                [_c("v-icon", [_vm._v("refresh")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Atualizações")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: { link: "" },
                              on: { click: _vm.darkTheme }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                { staticClass: "mr-3" },
                                [
                                  _c("v-icon", { attrs: { medium: "" } }, [
                                    _vm._v("brightness_medium")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.theme))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.showFeedback()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("bug_report")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Bug Report")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.showInfo()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("help_outline")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Sobre")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.handleLogOut()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("cancel")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Sair")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }